$(document).ready(function () {
    $("#searchString").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $(".media").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });

        if ($(".media:visible").length == 0) {
            $(".no-data").removeClass("d-none");
        } else {
            $(".no-data").addClass("d-none");
        }
    });
    $('[data-tooltip="tooltip"]').tooltip();

    /* on click #aszf */
    $("#aszf").click(function () {
        loadPageToModal("aszf");
    });
    $("#privacy").click(function () {
        loadPageToModal("privacy");
    });
    if (getCookie("chatmode") == "inactive") {
        $(".chat-head-small").html('<i class="fal fa-window-maximize"></i>');
        $(".chat-box").removeClass("active");
    } else {
        $(".chat-head-small").html('<i class="fal fa-window-minimize"></i>');
        $(".chat-box").addClass("active");
    }
});

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/* function loadASZF() {
    $(".modal-title").html("Általános Szerződési Feltételek");
    $(".modal-content .modal-body").load(
        "https://online.zomko.hu/" + online + "/aszf"
    );
}
function loadPrivacy() {
    $(".modal-title").html("Adatvédelmi tájékoztató");
    $(".modal-content .modal-body").load(
        "https://online.zomko.hu/" + online + "/adatvedelem"
    );
} */

/* function loadPageToModal(url) {
    if (url) {
        if (url.indexOf("privacy") > -1) {
            loadPrivacy();
        } else if (url.indexOf("aszf") > -1) {
            loadASZF();
        } else {
            $(".modal-title").html("Tartalom betöltése...");
            $(".modal-content .modal-body").load(url);
        }
    }
    $("#modal").modal("show");
} */

var setHeight = $(".img-3by4:first-child").width();
setHeight = setHeight * 1.2;
$(".img-3by4").wrap(
    '<div style="max-height:' + setHeight + 'px;overflow:hidden;"></div>'
);

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

$(".toast").on("hidden.bs.toast", function () {
    $(this).removeClass("new");
});

$.toast = function (options) {
    var settings = $.extend(
        {
            heading: "",
            text: "",
            position: "top-right",
            loaderBg: "#ff6849",
            icon: "info",
            hideAfter: 3500,
            stack: 6,
        },
        options
    );
    var toast = $("<div/>", {
        class: "toast " + settings.position,
    });

    $("<div/>", {
        class: "toast-header",
        html: $("<strong/>", {
            text: settings.heading,
        }),
    }).appendTo(toast);
    $("<div/>", {
        class: "toast-body",
        html: settings.text,
    }).appendTo(toast);

    toast.appendTo($("#toast"));
    toast.hide();
    toast.fadeIn(750);
    toast.delay(settings.hideAfter);
    toast.fadeOut(750, function () {
        $(this).remove();
    });
};

$("body").ready(function () {
    $(window).scroll(function (event) {
        if ($(this).scrollTop() > 75) {
            $("#headernav").addClass("head-bar-top");
            $(".scrollup").fadeIn();
        } else {
            $("#headernav").removeClass("head-bar-top");
            $(".scrollup").fadeOut();
        }
    });

    /* on click .scrollup go to top */
    /* $(".scrollup").click(function () {
        $("html, body").animate({ scrollTop: 0 }, 0);
        return false;
    }); */
});

/* on click small, remove .fix from .chat-head */
$(".chat-head-small").click(function () {
    /* if val contains _ then remove fix else add */
    if ($(this).html().includes('<i class="fal fa-window-minimize"></i>')) {
        $(this).html('<i class="fal fa-window-maximize"></i>');
        $(".chat-box").removeClass("active");
        setCookie("chatmode", "inactive", 365);
    } else {
        $(this).html('<i class="fal fa-window-minimize"></i>');
        $(".chat-box").addClass("active");
        setCookie("chatmode", "active", 365);
    }
});

/* hide preloader after loaded everything */
$(window).on("load", function () {
    $("#preloader").fadeOut("fast", function () {
        $(this).remove();
    });
});
